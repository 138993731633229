import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed",
  style: {"background-image":"url('media/background/skye-background.jpg')","background-size":"100%"}
}
const _hoisted_2 = { class: "d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("a", {
        href: "#",
        class: "mb-12 d-flex align-items-center"
      }, [
        _createElementVNode("img", {
          alt: "Logo",
          src: "/media/logos/skye-logo.png",
          class: "h-45px"
        }),
        _createElementVNode("span", { class: "version" }, "3.0")
      ], -1)),
      _createVNode(_component_router_view)
    ])
  ]))
}